import React from 'react'
import { Col, Row, Button } from 'antd'
import { Link } from 'gatsby'
import './footer_styles.scss'

const Footer = ({ data }) => (
  <div className='footer'>
    <div className='content'>
      <Row>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          Copyright (2020) &nbsp; // &nbsp; by{' '}
          <a href='https://www.onlinepunks.com/'>OnlinePunks</a>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <a href='https://www.onlinepunks.com/impressum'>Imprint</a>
          &nbsp;&nbsp;{'//'}&nbsp;&nbsp;
          <Link to='/privacy-policy'>Privacy Policy</Link>
          &nbsp;&nbsp;{'//'}&nbsp;&nbsp;
          <Link to='/termsandconditions'>Terms and Conditions</Link>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <img
            className='payment_methods'
            src='stripe.png'
            width='140'
            height='70'
          />{' '}
          &nbsp;&nbsp;
          <a href='https://www.onlinepunks.com/contact' target='_blank'>
            Contact Us
          </a>
        </Col>
      </Row>
    </div>
  </div>
)

export default Footer
