import React from 'react'
import PropTypes from 'prop-types'
import Footer from '@containers/Footer'
import CookiesConsent from '@common/CookiesConsent'

const HomeLayout = ({ children }) => {
  return (
    <>
      <div className='layoutCanvas'>
        <div className='homeWrapper'>
          <div className='main'>{children}</div>
        </div>
      </div>
      <Footer />
      <CookiesConsent />
    </>
  )
}

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HomeLayout
